.home {
    display: flex;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../images/background.jpg");
    background-size: cover;
    background-position: center center;
    background-blend-mode: darken;
    overflow: hidden;
}

.home .logo-title {
    margin: auto;
    font-size: 4em;
    font-weight: 400;
    width: 100%;
    text-align: center;
}

.home .logo-title img {
    width: min(90%, 800px);
}