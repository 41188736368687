/* Global Styles */
* {
  box-sizing: border-box;
}

@font-face {
  font-family: 'Typo Round';
  src: url('../Typo_Round_Light.otf');
  font-weight: normal;
}

body {
  background:#1a1a1a;
  color: #F8F9FA;
  font-family: 'Typo Round', serif;
  margin:0;
  line-height: 1.5;
}

.main-wrapper {
  display:grid;
  grid-template-columns: auto;
  grid-template-rows: 4em auto;
  grid-template-areas:
  "nav"
  "main";
  height: 100vh;
  overflow: hidden;
}

main {
  overflow: auto;
  grid-area: main;
}

nav {
  grid-area: nav;
}


@media screen and (max-width: 600px) {
  .main-wrapper {
    grid-template-rows: 6em auto;
  }
}

@media screen and (min-width: 1000px){
  .main-wrapper {
    grid-template-columns: 250px auto;
    grid-template-rows: auto;
    grid-template-areas:
    "nav main"
  }
}

.error {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}