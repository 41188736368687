.container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
}
.photo {
    max-height: 90vh;
    padding: 1em;
    text-align: center;
}